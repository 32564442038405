@media screen and (min-device-width:768px){
    body{
        background-color: rgb(231, 231, 231);
    }
    .homeBody{
        display: flex;
    }
    .homeWrapper{
        padding: 10px;
        margin-left: 15%;
        margin-top: 10%;
        background-color: white;
        width: 70%;
    }
    .mimage{
        width: 60%;
        margin-left: -25%;
    }
    .title{
        text-align: start;
        color: rgb(45, 45, 45);
    }
    .homeDetails{
        display: flex;
        flex-direction: column;
       
        margin-left: -10%;
    }
    .selectName{
        margin-top: 10px;
        height: 40px;
        border: 1px solid rgb(161, 161, 161);
    }
    .submit{
        width: 40%;
        margin-top: 10%;
        background-color: rgb(0, 0, 0);
        color: white;
        padding: 10px 25px 10px 25px;
    }
    .results{
        margin-left: 7%;
       
        width: 30%;
    }
    .res{
        text-align: start;
    }
}
@media screen and (max-width:468px){
    body{
        background-color: rgb(231, 231, 231);
    }
    .homeBody{
        display: flex;
        flex-direction: column;
    }
    .homeWrapper{
        padding: 10px;

        background-color: white;
        width: 100%;
    }
    .mimage{
        width: 90%;
        margin-left: -8%;
  
    }
    .title{
        text-align: start;
        color: rgb(45, 45, 45);
    }
    .homeDetails{
        display: flex;
        flex-direction: column;
       
       
    }
    .selectName{
        margin-top: 10px;
        height: 40px;
        width: 90%;
        border: 1px solid rgb(161, 161, 161);
    }
    .submit{
        width: 40%;
        margin-top: 10%;
        background-color: rgb(0, 0, 0);
        color: white;
        padding: 10px 25px 10px 25px;
    }
    .results{
   
       
        width: 100%;
    }
    .res{
        text-align: start;
    }
}